import { Category } from "../types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import FuzzySearch from "fuzzy-search";
import { recipeImage, slugify } from "../lib/utils";
import { supabase } from "../supabaseClient";
import Loader from "./Loader";

type PreviewProps = {
  recipeID: string;
  name: string;
  slug: string;
};

const Preview = ({ recipeID, name, slug }: PreviewProps) => {
  const imgUrl = recipeImage(slug, 300, 150);
  return (
    <Link to={`r/${recipeID}`}>
      <div className="Card">
        <header
          className="Card-header"
          style={{ backgroundImage: `url(${imgUrl})` }}
        ></header>
        <div className="Card-body">
          <h1>{name}</h1>
        </div>
      </div>
    </Link>
  );
};

type RecipePreview = {
  name: string;
  searchableName?: string;
  slug: string;
  img?: string;
  category: Category;
};

const Cards = () => {
  const [categories, setCategories] = useState<Record<Category, boolean>>({
    Breakfast: true,
    Appetizer: true,
    Main: true,
    Salad: true,
    Dessert: true,
    Snacks: true,
  });

  const [search, setSearch] = useState<string>("");
  const [allCards, setAllCards] = useState<RecipePreview[]>([]);
  const [cards, setCards] = useState<RecipePreview[]>([]);
  const [loading, setLoading] = useState(true);

  const toggleCategory = (category: Category) => {
    setCategories((categories) => {
      if (Object.values(categories).every((c) => c)) {
        return Object.fromEntries(
          Object.keys(categories).map((c) => [c, c === category])
        ) as Record<Category, boolean>;
      } else {
        return {
          ...categories,
          [category]: !categories[category],
        };
      }
    });
  };

  const loadCards = useCallback(async () => {
    const { data, error } = await supabase
      .from("recipes")
      .select("name,img,slug,category")
      .or("layout.is.null,layout.neq.Blank");
    if (data) {
      setLoading(false);
      const cards = data.map((card) => ({
        searchableName: slugify(card.name),
        ...card,
      })) as RecipePreview[];
      localStorage.setItem("allCards", JSON.stringify(cards));
      setAllCards(cards);
    }
    if (error) console.error(error);
  }, []);

  useEffect(() => {
    const cards = JSON.parse(localStorage.getItem("allCards") || "[]");
    if (cards) {
      setLoading(false);
      setAllCards(cards);
    }

    loadCards();
  }, [loadCards]);

  const searcher = useMemo(
    () => new FuzzySearch(allCards, ["searchableName"], { sort: true }),
    [allCards]
  );

  useEffect(() => {
    setCards(
      searcher
        .search(slugify(search))
        .filter((card) => categories[card.category])
    );
  }, [search, searcher, categories]);

  return (
    <div className="Overview">
      <h1>
        <img src="logo_white.png" alt="Manu's Recipes" />
        Manu's Recipes
      </h1>
      <input
        type="search"
        className="search"
        value={search}
        placeholder="Search..."
        onChange={(e) => setSearch(e.target.value)}
      />
      <div>
        {Object.entries(categories).map(([category, active]) => (
          <div
            className={`Category Category-${category} ${!active && "disabled"}`}
            key={category}
            onClick={() => toggleCategory(category as Category)}
          >
            {" "}
            {category}
          </div>
        ))}
      </div>
      <Loader loading={loading} />
      {cards.map(({ name, slug }) => {
        return <Preview name={name} slug={slug} recipeID={slug} key={slug} />;
      })}
    </div>
  );
};

export default Cards;
