import React, { useState, useCallback } from "react";
import NoSleep from "nosleep.js";

export type Timer = {
  name: string;
  duration: number;
  paused: boolean;
  end?: number;
};

export const TimerContext = React.createContext({
  timers: [] as Timer[],
  addTimer: (name: string, duration: number) => {},
  removeTimer: (name: string) => {},
  pauseTimer: (name: string) => {},
  startTimer: (name: string) => {},
});

type TimerProviderProps = {
  children: React.ReactNode;
};

const TimerProvider = ({ children }: TimerProviderProps) => {
  const [timers, setTimers] = useState<Timer[]>([]);
  const noSleep = new NoSleep();

  const removeTimer = (name: string) => {
    console.log("Removing timer", name);
    setTimers((timers) => {
      const result = timers.filter((t) => t.name !== name);
      if (result.length === 0) noSleep.disable();
      return result;
    });
  };

  const addTimer = (name: string, duration: number) => {
    console.log("Adding timer", name, duration, timers);
    setTimers((timers) => [...timers, { name, duration, paused: true }]);
    noSleep.enable();
  };

  const pauseTimer = (name: string) => {
    setTimers((timers) => {
      const timer = timers.find((t) => t.name === name);
      if (!timer) return timers;
      if (timer.end) timer.duration = (timer.end - new Date().getTime()) / 1000;
      timer.paused = true;
      return [...timers];
    });
  };

  const startTimer = (name: string) => {
    setTimers((timers) => {
      const timer = timers.find((t) => t.name === name);
      if (!timer) return timers;
      timer.end = new Date().getTime() + timer.duration * 1000;
      timer.paused = false;
      return [...timers];
    });
  };

  const contextValue = {
    timers,
    addTimer: useCallback(
      (name: string, duration: number) => addTimer(name, duration),
      // eslint-disable-next-line
      []
    ),
    removeTimer: useCallback(
      (name: string) => removeTimer(name),
      // eslint-disable-next-line
      [noSleep]
    ),
    pauseTimer: useCallback((name: string) => pauseTimer(name), []),
    startTimer: useCallback((name: string) => startTimer(name), []),
  };

  return (
    <TimerContext.Provider value={contextValue}>
      {children}
    </TimerContext.Provider>
  );
};

export default TimerProvider;
