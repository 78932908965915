import { RecipeCard as RecipeCardResult } from "index";
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkDirective from "remark-directive";
import remarkDirectiveRehype from "remark-directive-rehype";
import { Recipe as RecipeSchema, WithContext } from "schema-dts";
import { typesetIngredient } from "../lib/typeset";
import { Hidden, SimpleTemperature, SimpleTime } from "./inline";
import { Helmet } from "react-helmet";
import { recipeImage } from "../lib/utils";

const ISO8601Duration = (minutes: number) => {
  if (minutes < 60) return `PT${minutes}M`;
  return `PT${Math.floor(minutes / 60)}H${minutes % 60}M`;
};

const elementToPlainText = (element: any): string => {
  if (typeof element === "string") return element;
  else if (React.isValidElement(element)) {
    // @ts-ignore
    return elementToPlainText(element.props.children);
  } else if (Array.isArray(element)) {
    return element.map(elementToPlainText).join(" ");
  } else return element.toString();
};

const stepToPlainText = (step: string): string => {
  const element = (
    <ReactMarkdown
      remarkPlugins={[remarkDirective, remarkDirectiveRehype]}
      components={{
        p: ({ node, ...props }) => <div className="Timer-Name" {...props} />,
        // @ts-ignore
        preheat: SimpleTemperature,
        temp: SimpleTemperature,
        // @ts-ignore
        time: SimpleTime,
        hint: Hidden,
      }}
    >
      {step}
    </ReactMarkdown>
  );
  return elementToPlainText(element);
};

const RecipeMeta = ({
  name,
  slug,
  description,
  category,
  img,
  yield_amount,
  yield_unit,
  total_time,
  active_time,
  ingredients,
  steps,
}: RecipeCardResult) => {
  const url = `https://manu.recipes/r/${slug}`;
  const imgUrl = recipeImage(slug, 1200, 630);

  let metadata: WithContext<RecipeSchema> = {
    "@context": "https://schema.org",
    "@type": "Recipe",
    author: "Manu Ebert",
    name: name,
    url: url,
    prepTime: ISO8601Duration(active_time),
    recipeCategory: category,
    description: description,
    image: `https://manu.recipes/${img}`,
    recipeIngredient: ingredients
      .filter((i) => !i.is_header)
      .map(
        (ingredient) =>
          typesetIngredient(ingredient, { plaintext: true }) as string
      ),
    // nutrition: {
    //   "@type": "NutritionInformation",
    //   calories: "240 calories",
    //   fatContent: "9 grams fat",
    // },
    recipeInstructions: stepToPlainText(steps),
    recipeYield: `${yield_amount} ${yield_unit}`,
  };
  if (total_time) metadata.totalTime = ISO8601Duration(total_time);

  return (
    <>
      <Helmet>
        <title>{name}</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="article" />
        <meta property="og:article:author" content="Manu Ebert" />

        <meta property="og:url" content={url} />
        <meta property="og:title" content={name} />
        <meta property="og:site_name" content="Manu's Recipes" />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imgUrl} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta property="twitter:image" content={imgUrl} />
        <meta property="twitter:title" content={name} />
        <meta property="twitter:description" content={description} />
      </Helmet>
      <script type="application/ld+json">{JSON.stringify(metadata)}</script>;
    </>
  );
};

export default RecipeMeta;
