import React from "react";
import { typesetIngredient, typesetFraction } from "../lib";

import { ReactComponent as ShareIcon } from "../icons/share.svg";
import { ReactComponent as MeasureIcon } from "../icons/measure.svg";
import { Ingredient } from "../types";
import { ShoppingListContext } from "../lib/shoppinglist";
function SingleIngredient({ ingredient }: { ingredient: Ingredient }) {
  const { toggleIngredient } = React.useContext(ShoppingListContext);
  return (
    <li
      onClick={() => toggleIngredient(ingredient.name)}
      className={ingredient.checkedOff ? "checked" : ""}
    >
      {typesetIngredient(ingredient)}
    </li>
  );
}

const IngredientsSection = () => {
  const { ingredients } = React.useContext(ShoppingListContext);
  return (
    <ul className="Ingredients-list">
      {ingredients.map((ingredient, i) =>
        ingredient.is_header ? (
          <h3 key={i}>{ingredient.name}</h3>
        ) : (
          <SingleIngredient ingredient={ingredient} key={i} />
        )
      )}
    </ul>
  );
};
type IngredientsProps = {
  yield_unit: string;
  yield_amount: number;
};

const IngredientsList = ({ yield_unit, yield_amount }: IngredientsProps) => {
  const { share, scale, system, incrementScale, decrementScale, cycleSystem } =
    React.useContext(ShoppingListContext);

  const serving =
    Math.round(yield_amount * scale) === 1 && yield_unit.endsWith("s")
      ? yield_unit.slice(0, -1)
      : yield_unit;

  return (
    <div className="Ingredients">
      <div className="Ingredients-Header">
        <h2>Ingredients</h2>
        <div className="Ingredients-Buttons">
          <button onClick={decrementScale} className="scale">
            −
          </button>
          <button onClick={incrementScale} className="scale">
            +
          </button>
          <button className="system" onClick={cycleSystem}>
            <MeasureIcon fill="#3e8fb0" height={16} width={16} />
            {system}
          </button>
          <button className="share" onClick={share}>
            <ShareIcon fill="#3e8fb0" height={16} width={16} />
            Share
          </button>
        </div>
        <p className="yields">
          Makes {typesetFraction(yield_amount * scale)} {serving}
        </p>
      </div>

      <IngredientsSection />
    </div>
  );
};

export default IngredientsList;
