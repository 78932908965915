import React, { useCallback, useEffect, useState } from "react";
import { useAutosave } from "react-autosave";
import { parseUSDA, USDAResult } from "../../lib/usda";
import { supabase } from "../../supabaseClient";
import Loader from "../Loader";

type BaseIngredientMatcherProps = {
  id: number;
  name: string;
  base?: {
    id: number;
    name: string;
  };
};

type DropDownResult = [id: number, name: string];

const BaseIngredientMatcher = ({
  id,
  name,
  base,
}: BaseIngredientMatcherProps) => {
  const [search, setSearch] = useState(base ? base.name : name);
  const [loading, setLoading] = useState(false);
  const [optimisticName, setOptimisticName] = useState<string | null>(null);
  const [results, setResults] = useState<DropDownResult[]>([]);
  const [usdaResults, setUsdaResults] = useState<DropDownResult[]>([]);
  const [open, setOpen] = useState(false);

  const searchUSDA = useCallback(async () => {
    const response = await fetch(
      `https://api.nal.usda.gov/fdc/v1/foods/search?query=${search}&dataType=Foundation,Survey%20%28FNDDS%29,SR%20Legacy&pageSize=10&api_key=${process.env.REACT_APP_USDA_API_KEY}`
    );
    const json = await response.json();
    setUsdaResults(
      json.foods.map((food: any) => [food.fdcId, food.description])
    );
  }, [search]);

  const searchDainty = useCallback(async () => {
    if (!open) return;
    setLoading(true);
    searchUSDA();
    const { data, error } = await supabase
      .from("foods")
      .select("id, name")
      .ilike("name", `%${search}%`)
      .limit(10);
    if (error) console.error(error);
    setResults((data as any).map((row: any) => [row.id, row.name]));

    setLoading(false);
  }, [search, open, searchUSDA]);

  useAutosave({ data: search, onSave: searchDainty, interval: 200 });

  const close = () => {
    setOpen(false);
    setSearch(base ? base.name : name);
  };

  const pick = async (baseId: number, name: string) => {
    setLoading(true);
    setOptimisticName(name);
    close();

    const { error } = await supabase
      .from("ingredients")
      .update({ base: baseId })
      .eq("id", id);
    if (error) console.error(error);
    setLoading(false);
  };

  const create = async () => {
    setLoading(true);
    setOptimisticName(search);
    close();
    const { data, error } = await supabase
      .from("foods")
      .insert({ name: search, alternates: [] })
      .select("id, name")
      .single();
    if (error) console.error(error);
    if (data) {
      await supabase.from("ingredients").update({ base: data.id }).eq("id", id);
    }
    setLoading(false);
  };

  const createFromUSDA = async (usdaId: number, name: string) => {
    setLoading(true);
    setOptimisticName(name);
    close();
    console.debug("Fetching USDA data...");
    const response = await fetch(
      `https://api.nal.usda.gov/fdc/v1/food/${usdaId}?api_key=${process.env.REACT_APP_USDA_API_KEY}`
    );
    const usdaData: USDAResult = await response.json();
    console.debug(usdaData);
    const parsed = parseUSDA(usdaData);
    console.debug(parsed);

    /* ---------------------- Check if food already exists ---------------------- */
    console.debug(
      `Checking if food with USDA id ${parsed.usda_id} already exists...`
    );
    const { data: existingFood, error: existingFoodError } = await supabase
      .from("foods")
      .select("id,nutrition")
      .eq("usda_id", parsed.usda_id)
      .maybeSingle();
    if (existingFoodError) console.error(existingFoodError);
    console.debug(
      existingFood ? "Found existing food" : "No existing food found"
    );
    console.log(existingFood);

    /* ------------------ Create Nutrition if it doesn't exist ------------------ */
    const nutritionId = (existingFood && existingFood.nutrition) || undefined;
    console.debug("Saving nutrition data");
    const { data: nutritionData, error: nutritionError } = await supabase
      .from("nutrition")
      .upsert({ id: nutritionId, ...parsed.nutrients })
      .select("id")
      .single();
    if (nutritionError) console.error(nutritionError);
    console.debug("Saving food data");
    const { data, error } = await supabase
      .from("foods")
      .upsert({
        id: existingFood?.id,
        name: parsed.name,
        usda_id: parsed.usda_id,
        density: parsed.density || undefined,
        category: parsed.category,
        nutrition: nutritionData?.id,
      })
      .select("id, name")
      .single();

    if (error) console.error(error);
    if (data) {
      await supabase.from("ingredients").update({ base: data.id }).eq("id", id);
    }

    setLoading(false);
  };

  // Search when opened
  useEffect(() => {
    if (open) searchDainty();
  }, [open, searchDainty]);

  // Display static element until we click on it
  if (!open) {
    return (
      <span
        className={`FakeDropDown ${!base && !optimisticName && "placeholder"}`}
        onClick={() => setOpen(true)}
      >
        {base ? base.name : optimisticName ? optimisticName : "Pick..."}
        <Loader loading={loading} inline />
      </span>
    );
  }

  return (
    <span className="DropDownWrapper">
      <input
        className="Editable"
        value={search}
        onKeyUp={(e) => {
          if (e.key === "Escape") close();
        }}
        onChange={(e) => setSearch(e.target.value)}
      />

      <div className="DropDown">
        {results.map((result) => (
          <div
            key={result[0]}
            className="DropDownItem"
            onClick={() => pick(...result)}
          >
            {result[1]}
          </div>
        ))}
        {usdaResults.map((result) => (
          <div
            key={result[0]}
            className="DropDownItem USDA"
            onClick={() => createFromUSDA(...result)}
          >
            {result[1]}
          </div>
        ))}
        <div className="DropDownItem Error" onClick={() => create()}>
          Create "{search}"
        </div>
      </div>
    </span>
  );
};

export default BaseIngredientMatcher;
