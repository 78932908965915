import React, { useContext, useId } from "react";
import { Tooltip } from "react-tooltip";
import { parseIngredient, typesetIngredient } from "../lib";
import { ShoppingListContext } from "../lib/shoppinglist";
import { TimerContext } from "../lib/timers";

export const Hint = ({ children }: { children: string }) => (
  <aside className="hint">{children}</aside>
);

export const InlineIngredient = ({
  children,
}: {
  children: string | string[];
}) => {
  const { adjustIngredient } = useContext(ShoppingListContext);
  const raw = Array.isArray(children) ? children[0] : children;
  return (
    <span className="">
      {typesetIngredient(adjustIngredient(parseIngredient(raw)), {
        plaintext: true,
      })}
    </span>
  );
};

export const Hidden = () => "";

type TimeProps = {
  min: number;
  name?: string;
  interactive?: boolean;
  children: React.ReactNode;
};

export const Time = ({
  min,
  name,
  children,
  interactive = true,
}: TimeProps) => {
  const { addTimer } = useContext(TimerContext);
  const contents = min < 1 ? `${Math.round(min * 60)} seconds` : `${min}min`;
  const id = useId();

  if (!interactive) return <>{children || contents}</>;
  return (
    <span
      className="time"
      id={id}
      onClick={() => addTimer(name || "", min * 60)}
    >
      <Tooltip anchorId={id} content="Tap to set timer" className="tooltip" />
      {children || contents}
    </span>
  );
};

export const SimpleTime = ({ min, children }: TimeProps) => (
  <Time min={min} interactive={false}>
    {children}
  </Time>
);

export type TemperatureProps = {
  f?: number;
  c?: number;
  interactive?: boolean;
  children?: React.ReactNode;
};

export const Temperature = ({
  f,
  c,
  interactive = true,
  children,
}: TemperatureProps) => {
  const id = useId();
  let tooltip = "";
  let contents = "";
  if (f) {
    let celsius = Math.round(((f - 32) * (5 / 9)) / 10) * 10;
    tooltip = `${celsius}ºC`;
    contents = `${f}ºF`;
  } else if (c) {
    let fahrenheit = Math.round(((c * 9) / 5 + 32) / 10) * 10;
    tooltip = `${fahrenheit}ºF`;
    contents = `${c}ºC`;
  }
  if (!interactive) return <>{children || contents}</>;
  return (
    <span className="temperature" id={id}>
      <Tooltip anchorId={id} content={tooltip} className="tooltip" />
      {children || contents}
    </span>
  );
};

export const SimpleTemperature = (props: TemperatureProps) => (
  <Temperature {...props} interactive={false} />
);
