import React, { CSSProperties } from "react";
import ClockLoader from "react-spinners/ClockLoader";

const override: CSSProperties = {
  display: "flex",
  justifyContent: "center",
  margin: "2em auto",
};

const inlineOverride: CSSProperties = {
  display: "inline-block",
};

const Loader = ({
  loading,
  inline,
}: {
  loading: boolean;
  inline?: boolean;
}) => {
  if (inline)
    return (
      <ClockLoader
        loading={loading}
        color="#8c9ca9"
        size={16}
        cssOverride={inlineOverride}
      />
    );
  return (
    <ClockLoader loading={loading} color="#8c9ca9" cssOverride={override} />
  );
};

export default Loader;
