type USDANutrient = {
  nutrient: {
    number: string;
    name: string;
    unitName: string;
  };
  amount: 91.4;
};

type USDAPortion = {
  gramWeight: number;
  amount?: number;
  modifier: string | number;
  portionDescription?: string;
};

export type USDAResult = {
  fdcId: number;
  description: string;
  foodNutrients: USDANutrient[];
  foodPortions?: USDAPortion[];
  wweiaFoodCategory?: {
    wweiaFoodCategoryDescription: string;
  };
  foodCategory?: {
    description: string;
  };
};

const nutrientMap = {
  "255": "water",
  "208": "kcal",
  "203": "protein",
  "205": "carbs",
  "291": "fiber",
  "269": "sugar",
  "204": "total_fat",
  "606": "saturated_fat",
};

const findDensity = (usdaResult: USDAResult) => {
  if (!usdaResult.foodPortions) return null;
  for (const portion of usdaResult.foodPortions) {
    if (portion.modifier === "cup" || portion.portionDescription === "1 cup") {
      // Convert from g/cup to kg/m3
      return (portion.gramWeight / (portion.amount || 1)) * 4.2268999915;
    }
  }
  return null;
};

type ParsedFood = {
  name: string;
  category: string | null;
  usda_id: string;
  density: number | null;
  nutrients: {
    water?: number;
    kcal?: number;
    protein?: number;
    carbs?: number;
    fiber?: number;
    sugar?: number;
    total_fat?: number;
    saturated_fat?: number;
  };
};

export const parseUSDA = (usdaResult: USDAResult): ParsedFood => {
  const nutrients: any = {};
  /* ---------------------------- Parse ingredients --------------------------- */
  usdaResult.foodNutrients.forEach((nutrient) => {
    const nutrientId = nutrient.nutrient.number;
    if (nutrientId in nutrientMap) {
      const nutrientName = nutrientMap[nutrientId as keyof typeof nutrientMap];
      nutrients[nutrientName] = nutrient.amount;
    }
  });

  /* --------------- Try to find the weight of 100g of the food --------------- */

  let food: ParsedFood = {
    name: usdaResult.description,
    category: null,
    usda_id: String(usdaResult.fdcId),
    density: findDensity(usdaResult),
    nutrients,
  };
  if (usdaResult.wweiaFoodCategory) {
    food.category = usdaResult.wweiaFoodCategory.wweiaFoodCategoryDescription;
  } else if (usdaResult.foodCategory) {
    food.category = usdaResult.foodCategory.description;
  }
  return food;
};
