import { Ingredient, Ingredients, SystemOfMeasurement } from "../types";
import React, { useState, useCallback, useEffect } from "react";
import {
  convert as convertIngredient,
  scale as scaleIngredient,
  typesetIngredient,
} from "../lib";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";

export const ShoppingListContext = React.createContext({
  scale: 1,
  incrementScale: () => {},
  decrementScale: () => {},
  cycleSystem: () => {},
  system: "default",
  setIncrement: (increment: number) => {},
  ingredients: [] as Ingredients,
  setIngredients: (ingredients: Ingredients) => {},
  adjustIngredient: (ingredient: Ingredient) => ingredient,
  toggleIngredient: (name: string) => {},
  share: () => {},
});

type ShoppingListProviderProps = {
  children: React.ReactNode;
};

const ShoppingListProvider = ({ children }: ShoppingListProviderProps) => {
  const [ingredients, setIngredients] = useState<Ingredients>([]);
  const [parsedIngredients, setParsedIngredients] = useState<Ingredients>([]);
  const [scale, setScale] = useState(1);
  const [system, setSystem] = useState<SystemOfMeasurement>("default");
  const [increment, setIncrement] = useState(1);

  const adjustIngredient = useCallback(
    (ingredient: Ingredient) =>
      convertIngredient(scaleIngredient(ingredient, scale), system),
    [scale, system]
  );

  useEffect(() => {
    setParsedIngredients(ingredients.map(adjustIngredient));
  }, [ingredients, setParsedIngredients, scale, system, adjustIngredient]);

  const cycleSystem = useCallback(() => {
    setSystem((system) =>
      system === "metric"
        ? "imperial"
        : system === "imperial"
        ? "default"
        : "metric"
    );
  }, []);

  const toggleIngredient = useCallback((name: string) => {
    setIngredients((ingredients) =>
      ingredients.map((i) => {
        if (i.name === name) {
          return { ...i, checkedOff: !(i as Ingredient).checkedOff };
        } else return i;
      })
    );
  }, []);

  const share = useCallback(() => {
    let text =
      "Shopping List:\n\n" +
      parsedIngredients
        .filter(
          (i) => !("isSectionHeader" in i) && !(i as Ingredient).checkedOff
        )
        .map((ingredient) => typesetIngredient(ingredient, { plaintext: true }))
        .join("\n");

    if (navigator.share) {
      navigator.share({ text });
    } else {
      copy(text);
      toast("Shopping list copied to clipboard", { type: "success" });
    }
  }, [parsedIngredients]);

  const incrementScale = useCallback(
    () => setScale((scale) => scale + 1 / increment),
    [setScale, increment]
  );

  const decrementScale = useCallback(
    () => setScale((scale) => Math.max(1 / increment, scale - 1 / increment)),
    [setScale, increment]
  );

  const contextValue = {
    setIngredients,
    scale,
    system,
    ingredients: parsedIngredients,
    setIncrement,
    incrementScale,
    decrementScale,
    cycleSystem,
    adjustIngredient,
    toggleIngredient,
    share,
  };

  return (
    <ShoppingListContext.Provider value={contextValue}>
      {children}
    </ShoppingListContext.Provider>
  );
};

export default ShoppingListProvider;
