import { SystemOfMeasurement } from "../types";

export type UnitDimension = "mass" | "volume" | "length";

export type Unit = {
  name: string;
  alternates?: string[];
  dimension: UnitDimension;
  system: SystemOfMeasurement;
  plural?: string;
  si_anchor: number;
};

export const units: Record<string, Unit> = {
  mg: {
    name: "mg",
    system: "metric",
    alternates: ["milligram", "milligrams"],
    dimension: "mass",
    si_anchor: 0.001,
  },
  g: {
    name: "g",
    system: "metric",
    alternates: ["gram", "grams"],
    dimension: "mass",
    si_anchor: 1,
  },
  kg: {
    name: "kg",
    system: "metric",
    alternates: ["kilogram", "kilograms"],
    dimension: "mass",
    si_anchor: 1000,
  },
  oz: {
    name: "oz",
    system: "imperial",
    alternates: ["ounce", "ounces"],
    dimension: "mass",
    si_anchor: 28.3495,
  },
  lb: {
    name: "lb",
    system: "imperial",
    alternates: ["pound", "pounds", "lbs"],
    dimension: "mass",
    plural: "lbs",
    si_anchor: 453.592,
  },

  ml: {
    name: "ml",
    system: "metric",
    alternates: ["milliliter", "milliliters", "milliliters"],
    dimension: "volume",
    si_anchor: 0.001,
  },
  cl: {
    name: "cl",
    alternates: ["centiliter", "centiliters", "centiliters"],
    system: "metric",
    dimension: "volume",
    si_anchor: 0.01,
  },
  dl: {
    name: "dl",
    alternates: ["deciliter", "deciliters", "deciliters"],
    system: "metric",
    dimension: "volume",
    si_anchor: 0.1,
  },
  l: {
    name: "l",
    alternates: ["liter", "liters", "liters"],
    system: "metric",
    dimension: "volume",
    si_anchor: 1,
  },

  tsp: {
    name: "tsp",
    alternates: ["teaspoon", "teaspoons"],
    system: "imperial",
    dimension: "volume",
    si_anchor: 0.00492892,
  },
  tbsp: {
    name: "tbsp",
    alternates: ["tablespoon", "tablespoons", "tbs"],
    system: "imperial",
    dimension: "volume",
    si_anchor: 0.0147868,
  },
  "fl-oz": {
    name: "fl-oz",
    alternates: ["fluid ounce", "fluid ounces", "floz"],
    system: "imperial",
    dimension: "volume",
    si_anchor: 0.0295736,
  },
  cup: {
    name: "cup",
    alternates: ["cups"],
    system: "imperial",
    plural: "cups",
    dimension: "volume",
    si_anchor: 0.236588,
  },
  pnt: {
    name: "pint",
    alternates: ["pints", "pt", "pts", "pnt", "pnts"],
    plural: "pints",
    system: "imperial",
    dimension: "volume",
    si_anchor: 0.4731759999986023,
  },
  qt: {
    name: "qt",
    alternates: ["quart", "quarts", "qts"],
    system: "imperial",
    dimension: "volume",
    si_anchor: 0.94635199999720465147,
  },
  gal: {
    name: "gal",
    alternates: ["gallon", "gallons", "gals"],
    system: "imperial",
    dimension: "volume",
    si_anchor: 3.7854079999888186059,
  },
  m: {
    name: "m",
    alternates: ["meter", "meters", "metre", "metres"],
    system: "metric",
    dimension: "length",
    si_anchor: 1,
  },
  pinch: {
    name: "pinch",
    alternates: ["pinches"],
    plural: "pinches",
    system: "default",
    dimension: "mass",
    si_anchor: 1,
  },
  bunch: {
    name: "bunch",
    alternates: ["bunches", "a bunch"],
    plural: "bunches",
    system: "default",
    dimension: "mass",
    si_anchor: 1,
  },
  large: {
    name: "large",
    system: "default",
    dimension: "mass",
    si_anchor: 1,
  },
  clove: {
    name: "clove",
    alternates: ["cloves"],
    plural: "cloves",
    system: "default",
    dimension: "mass",
    si_anchor: 1,
  },
  small: {
    name: "small",
    system: "default",
    dimension: "mass",
    si_anchor: 1,
  },
  can: {
    name: "can",
    system: "default",
    alternates: ["cans"],
    plural: "cans",
    dimension: "volume",
    si_anchor: 1,
  },
  sprig: {
    name: "sprig",
    alternates: ["sprigs"],
    system: "default",
    plural: "sprigs",
    dimension: "mass",
    si_anchor: 1,
  },
};

type Units = keyof typeof units;

export const anchorUnits: Record<UnitDimension, Units> = {
  volume: "l",
  mass: "g",
  length: "m",
};

// Map alternates back to the original units
export const alternates: Record<string, Units> = Object.fromEntries(
  Object.entries(units)
    .map(([k, v]) => (v.alternates || []).map((a) => [a, k]).concat([[k, k]]))
    .flat(1)
);

export const imperialToMetric: Record<Units, Units> = {
  oz: "g",
  lb: "g",
  gal: "l",
  tsp: "g",
  tbsp: "g",
  "fl-oz": "ml",
  cup: "g",
  pnt: "ml",
  qt: "ml",
};

export const metricToImperial: Record<Units, Units> = {
  mg: "oz",
  g: "cup",
  kg: "cup",
  ml: "tsp",
  cl: "cup",
  dl: "cup",
  l: "cup",
  tsp: "tsp",
  tbsp: "tbsp",
};
