const fluids: string[] = [
  "beer",
  "gin",
  "run",
  "vodka",
  "whiskey",
  "whisky",
  "bourbon",
  "scotch",
  "tequila",
  "sake",
  "wine",
  "red wine",
  "white wine",
  "apple juice",
  "almond milk",
  "coconut cream",
  "coconut milk",
  "coconut water",
  "coffee",
  "cranberry juice",
  "half and half",
  "heavy whipping cream",
  "cream",
  "fish broth",
  "lemon juice",
  "whole milk",
  "milk",
  "buttermilk",
  "chocolate milk",
  "oil",
  "almond oil",
  "avocado oil",
  "canola oil",
  "cocoa butter oil",
  "coconut oil",
  "corn and canola oil",
  "cottonseed oil",
  "flaxseed oil",
  "grapeseed oil",
  "hazelnut oil",
  "mustard oil",
  "olive oil",
  "palm oil",
  "peanut oil",
  "rice bran oil",
  "safflower oil",
  "sesame oil",
  "soybean oil",
  "sunflower oil",
  "vegetable oil",
  "orange juice",
  "soymilk",
  "soy milk",
  "syrup",
  "tea",
  "tomato juice",
  "vanilla extract",
  "balsamic vinegar",
  "cider vinegar",
  "white vinegar",
  "vinegar",
  "red wine vinegar",
  "water",
];

export default fluids;
