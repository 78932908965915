import React, { useCallback, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { supabase } from "../supabaseClient";
import BaseIngredientMatcher from "./admi/BaseIngredientMatcher";
import Editable from "./admi/Editable";
import Loader from "./Loader";
import { AdminIngredientResult, AdminRecipeResult } from "index";
import Nutrition from "./Nutrition";

const IngredientRow = ({
  id,
  name,
  amount,
  unit,
  is_header,
  preparation,
  base,
}: AdminIngredientResult) => {
  if (is_header)
    return (
      <tr>
        <td colSpan={4}>
          <Editable
            table="ingredients"
            id={id}
            field="name"
            value={name}
            className="header"
          />
        </td>
      </tr>
    );

  return (
    <tr title={`Ingredient ${id}`}>
      <td>
        <Editable
          table="ingredients"
          id={id}
          field="amount"
          value={amount}
          className="short"
        />
      </td>
      <td>
        <Editable
          table="ingredients"
          id={id}
          field="unit"
          value={unit}
          className="short"
        />
      </td>
      <td>
        <Editable table="ingredients" id={id} field="name" value={name} />
      </td>
      <td>
        <BaseIngredientMatcher id={id} name={name} base={base} />
      </td>
      <td>
        <Editable
          json
          table="ingredients"
          id={id}
          field="preparation"
          value={preparation}
        />
      </td>
    </tr>
  );
};

const Admin = () => {
  let params = useParams();
  let recipeId = params.id as string;
  const [recipe, setRecipe] = useState<AdminRecipeResult>();
  const [loading, setLoading] = useState(true);

  const loadData = useCallback(async () => {
    const { data, error } = await supabase
      .from("recipes")
      .select(
        `
        name,
        id,
        steps,
        description,
        ingredients (
          id,
          name,
          amount,
          unit,
          preparation,
          is_header,
          base (
            id,
            name,
            density,
            nutrition (
              water, kcal, protein, carbs, fiber, sugar, total_fat, saturated_fat
            )
          )
        )`
      )
      .eq("slug", recipeId)
      .limit(1)
      .single();
    if (data) {
      setLoading(false);
      (data as AdminRecipeResult).ingredients.sort((a, b) => a.id - b.id);
      setRecipe(data as AdminRecipeResult);
    }
    if (error) console.error(error);
  }, [recipeId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (!recipe || loading) return <Loader loading={loading} />;

  return (
    <div className="Admin">
      <h1>{recipe.name}</h1>
      <table>
        <tbody>
          {recipe.ingredients.map((ingredient) => (
            <IngredientRow key={ingredient.id} {...ingredient} />
          ))}
        </tbody>
      </table>
      <h2>Description</h2>
      <Editable
        textarea
        table="recipes"
        id={recipe.id}
        field="description"
        value={recipe.description}
      />
      <h2>Steps</h2>
      <Editable
        textarea
        table="recipes"
        id={recipe.id}
        field="steps"
        value={recipe.steps}
      />
      <Nutrition ingredients={recipe.ingredients} />
    </div>
  );
};

export default Admin;
