import densities from "./density";
import fluids from "./fluids";
import { Ingredient, SectionHeader, SystemOfMeasurement } from "../types";
import {
  anchorUnits,
  imperialToMetric,
  metricToImperial,
  units,
} from "./units";

export const scale = (
  ingredient: Ingredient | SectionHeader,
  factor: number
): Ingredient | SectionHeader => {
  if ("isSectionHeader" in ingredient) {
    return ingredient;
  }
  return {
    ...ingredient,
    amount: ingredient.amount ? ingredient.amount * factor : ingredient.amount,
    range: ingredient.range ? ingredient.range * factor : ingredient.range,
  };
};

export const convert = (
  ingredient: Ingredient,
  to: SystemOfMeasurement
): Ingredient => {
  if (ingredient.is_header) {
    return ingredient;
  }
  if (!ingredient.amount) {
    return ingredient;
  }

  if (!ingredient.unit || to === "default") return ingredient;

  if (!(ingredient.unit in units)) {
    console.warn("Unknown unit", ingredient);
    return ingredient;
  }

  const sourceUnit = units[ingredient.unit];
  if (sourceUnit.system === to) {
    return ingredient;
  }

  let baseIngredient = ingredient.baseIngredient || ingredient.name;
  let anchorUnit = units[anchorUnits[sourceUnit.dimension]];
  let anchorAmount = ingredient.amount * sourceUnit.si_anchor;

  let targetUnit = sourceUnit;

  if (to === "metric") {
    if (
      fluids.includes(baseIngredient) &&
      ["cup", "tsp", "tbsp"].includes(sourceUnit.name)
    ) {
      targetUnit = units.ml;
    } else {
      targetUnit =
        units[imperialToMetric[ingredient.unit]] || units[ingredient.unit];
    }
  } else if (to === "imperial") {
    if (sourceUnit.system === "default") targetUnit = sourceUnit;
    else if (sourceUnit.dimension === "volume" && anchorUnit.name === "l") {
      // anchorUnit is l, find the target unit that makes most sense:
      if (anchorAmount < 0.015) targetUnit = units.tsp;
      if (anchorAmount < 0.055) targetUnit = units.tbsp;
      else targetUnit = units.cup;
    } else if (sourceUnit.dimension === "mass" && anchorUnit.name === "g") {
      if (anchorAmount < 15) targetUnit = units.tsp;
      if (anchorAmount < 55) targetUnit = units.tbsp;
      else targetUnit = units.cup;
    } else
      targetUnit =
        units[metricToImperial[ingredient.unit]] || units[ingredient.unit];
  }

  if (targetUnit === sourceUnit) return ingredient;

  if (anchorUnit.dimension !== targetUnit.dimension) {
    // try to convert between volume and mass first
    let density = densities[baseIngredient];
    if (!density) {
      console.warn("No density for", ingredient);
      if (targetUnit.name === "cup") targetUnit = units.oz;
      else return ingredient;
    } else if (anchorUnit.dimension === "volume") {
      anchorAmount *= density;
      anchorUnit = units[anchorUnits.mass];
    } else {
      anchorAmount /= density;
      anchorUnit = units[anchorUnits.volume];
    }
  }

  let targetAmount = anchorAmount / targetUnit.si_anchor;

  return {
    ...ingredient,
    amount: targetAmount,
    unit: targetUnit.name,
  };
};
