// Densities are in kg/m3. To convert to g/cup, multiply by 0.23658

const densities: Record<string, number> = {
  beer: 997,
  gin: 940,
  run: 940,
  vodka: 940,
  whiskey: 940,
  whisky: 940,
  bourbon: 940,
  scotch: 940,
  tequila: 940,
  sake: 983,
  wine: 997,
  "red wine": 997,
  "white wine": 997,
  "alfalfa seeds": 139,
  "almond paste": 1094,
  amaranth: 1039,
  "bacon grease": 872,
  "apple butter": 1191,
  "apple juice": 1048,
  "apple sauce": 1077,
  applesauce: 1077,
  apricots: 502,
  artichokes: 710,
  arugula: 84,
  asparagus: 566,
  "bacon bits": 473,
  bacon: 608,
  "baking powder": 803,
  "baking soda": 1014,
  "mashed banana": 959,
  barley: 777,
  "adzuki beans": 832,
  "baked beans prepared": 1069,
  "black turtle beans": 777,
  "black beans": 819,
  "fava beans": 532,
  "french beans": 777,
  "kidney beans": 777,
  "mung beans": 528,
  "navy beans": 439,
  "pinto beans": 815,
  "white beans": 853,
  "beet greens": 160,
  "almond milk": 1014,
  blackberries: 608,
  blueberries: 625,
  "panko bread crumbs": 211,
  "bread crumbs": 456,
  broccoli: 777,
  "brussels sprouts, cooked": 659,
  "brussels sprouts": 371,
  "buckwheat groats, roasted, cooked": 710,
  "buckwheat groats, roasted, dry": 693,
  buckwheat: 718,
  "bulgur, cooked": 769,
  "bulgur, dry": 591,
  "butter oil": 866,
  butter: 972,
  "butter, whipped": 638,
  "candies, milk chocolate coated peanuts": 629,
  "candies, milk chocolate coated raisins": 760,
  "carrot juice, canned": 997,
  "carrot, dehydrated": 312,
  "carrots, cooked": 655,
  "carrots, diced": 600,
  "carrots, grated": 418,
  cassava: 870,
  "cauliflower, green": 270,
  celeriac: 659,
  "celery, diced": 600,
  celery: 507,
  "rice krispies": 118,
  "corn flakes": 105,
  "corn grits": 659,
  farina: 743,
  granola: 515,
  "cereal, oats, instant, prepared": 1014,
  "swiss chard": 152,
  "cheddar cheese": 946,
  camembert: 1039,
  "grated mozzarella": 477,
  "grated cheddar": 477,
  "cottage cheese": 959,
  "cream cheese": 980,
  "goat cheese": 980,
  "feta cheese": 477,
  parmesan: 422,
  ricotta: 1048,
  "ricotta cheese": 1048,
  chickpeas: 845,
  "garbanzo beans": 845,
  "chicory, witloof": 380,
  "chocolate chips": 760,
  "chocolate syrup": 1268,
  "cocoa powder": 422,
  "coconut cream": 1014,
  "coconut milk": 1014,
  "coconut water": 1014,
  "coconut, sweetened, flakes": 359,
  "coconut, unsweetened, grated": 477,
  "coffee, brewed": 1000,
  "coffee, instant, regular, powder": 202,
  "coriander (cilantro) leaves": 67,
  "corn bran, crude": 321,
  "corn, canned, cream style": 1082,
  "corn, canned, whole kernel, drained solids": 693,
  "corn, canned, whole kernel, solids and liquids": 1082,
  "corn, cooked": 629,
  "corn, kernels, boiled, drained": 697,
  "corn, kernels, frozen": 574,
  corn: 612,
  "corn, white or yellow": 701,
  "cornmeal, degermed": 663,
  "cornmeal, whole-grain": 710,
  cornstarch: 541,
  "couscous, dry": 731,
  "cracker meal": 486,
  "cranberries, dried, sweetened": 512,
  "cranberry juice, unsweetened": 1069,
  "cranberry sauce, canned, sweetened": 1170,
  "cream of tartar": 608,
  "cream substitute, liquid": 1022,
  "cream substitute, powdered": 397,
  "cream, half and half": 1022,
  "cream, half and half, fat free": 946,
  "cream, heavy whipping": 1014,
  "cream, light (coffee cream or table cream)": 1014,
  "cream, light whipping": 1014,
  "cream, whipped, cream topping, pressurized": 253,
  "cress, garden, cooked": 570,
  "cress, garden": 211,
  "croutons, plain": 126,
  "croutons, seasoned": 169,
  currants: 473,
  "dessert topping, pressurized": 295,
  "dessert topping, semi solid, frozen": 317,
  "dulce de leche": 1284,
  edamame: 655,
  "egg substitute": 1014,
  egg: 1027,
  "egg white": 1027,
  "egg yolk": 1027,
  eggnog: 1073,
  elderberries: 612,
  "fat, beef tallow": 866,
  "fat, chicken": 866,
  "fat, duck": 866,
  "fat, goose": 866,
  "fat, mutton tallow": 866,
  "fat, turkey": 866,
  "figs, dried, uncooked": 629,
  "fish broth": 1031,
  "flax meal": 422,
  flaxseed: 591,
  flour: 591,
  "00 flour": 591,
  "all-purpose flour": 591,
  "almond flour": 405,
  "arrowroot flour": 541,
  "barley malt flour": 684,
  "barley flour": 625,
  "bread flour": 663,
  "buckwheat flour": 507,
  "cake flour": 549,
  "carob flour": 435,
  "corn flour": 532,
  masa: 481,
  "gluten-free flour": 659,
  "millet flour": 502,
  "oat flour": 439,
  "pastry flour": 477,
  "peanut flour": 253,
  "potato flour": 676,
  "rice flour": 667,
  "rye flour": 541,
  "self-rising flour": 477,
  "sorghum flour": 511,
  "soy flour": 351,
  "whole wheat flour": 591,
  "whole-grain flour": 507,
  "frozen yogurt": 735,
  garlic: 574,
  "ginger root": 405,
  "graham cracker crumbs, boxed": 418,
  "graham crackers, crushed": 600,
  honey: 1432,
  hummus: 1039,
  "ice cream": 625,
  "ice cream, soft serve": 727,
  "jam, jelly and preserves": 1352,
  ketchup: 1014,
  lard: 866,
  "lemon juice": 1031,
  "lemon peel": 405,
  lentils: 811,
  "lima beans": 659,
  "lime juice": 1022,
  litchis: 803,
  margarine: 946,
  marmalade: 1352,
  "marshmallow creme": 359,
  "marshmallow fluff": 541,
  "marshmallows, mini": 181,
  mayonnaise: 946,
  "meringue powder": 727,
  milk: 959,
  buttermilk: 1077,
  "condensed milk": 1293,
  "evaporated milk": 1065,
  "chocolate milk": 1056,
  "cooked millet": 735,
  "puffed millet": 88,
  millet: 845,
  miso: 1162,
  molasses: 1424,
  mulberries: 591,
  "chanterelle mushrooms": 228,
  "morel mushrooms": 278,
  mustard: 1052,
  natto: 739,
  "egg noodles, cooked": 676,
  "egg noodles": 160,
  "soba noodles": 481,
  "somen noodles": 743,
  "sliced almonds": 363,
  "slivered almonds": 481,
  almonds: 615,
  cashews: 581,
  chestnuts: 612,
  "roasted chestnuts": 604,
  hazelnuts: 600,
  macadamia: 629,
  "mixed nuts": 579,
  pecans: 464,
  pilinuts: 507,
  "pine nuts": 570,
  pistachios: 519,
  "chopped walnuts": 477,
  walnuts: 527,
  "oat bran, cooked": 925,
  "oat bran": 397,
  "steel cut oats": 836,
  "rolled oats": 418,
  oil: 921,
  "almond oil": 921,
  "avocado oil": 921,
  "canola oil": 921,
  "cocoa butter oil": 921,
  "coconut oil": 921,
  "corn and canola oil": 946,
  "cottonseed oil": 921,
  "flaxseed oil": 921,
  "grapeseed oil": 921,
  "hazelnut oil": 921,
  "mustard oil": 921,
  "olive oil": 912,
  "palm oil": 912,
  "peanut oil": 912,
  "rice bran oil": 921,
  "safflower oil": 921,
  "sesame oil": 921,
  "soybean oil": 921,
  "sunflower oil": 921,
  "vegetable oil": 951,
  okra: 422,
  "orange juice": 1052,
  "orange peel": 405,
  "oranges, with peel": 718,
  "parsley, fresh": 256,
  "peanut butter": 1141,
  "peanuts, dry-roasted": 617,
  peanuts: 617,
  "peas, green, cooked": 676,
  "peas, green, frozen, unprepared": 566,
  "peas, green": 612,
  "peas, split, cooked, boiled": 828,
  "peas, split": 832,
  "peas, sprouted": 507,
  "pepper, banana": 524,
  "peppermint, fresh": 108,
  "pickle relish": 1031,
  popcorn: 33,
  "potatoes, baked": 515,
  "potatoes, boiled, cooked": 659,
  "potatoes, mashed": 887,
  "prickly pears": 629,
  "prune juice, canned": 1082,
  "prune puree": 1217,
  "puddings, dry mix, regular or instant, prepared": 1183,
  "pumpkin pie mix, canned": 1141,
  "pumpkin, canned": 1035,
  "purslane, cooked": 486,
  purslane: 181,
  "queso cotija": 405,
  "quinoa, cooked": 781,
  "quinoa, uncooked": 718,
  "radish seeds, sprouted": 160,
  "raisins, loose": 591,
  "raisins, packed": 718,
  raspberries: 519,
  "raspberries, wild (northern plains indians)": 549,
  "refried beans, canned": 1005,
  "rice bran, crude": 498,
  "rice noodles, cooked": 743,
  "rice, brown, long-grain, cooked": 824,
  "rice, brown, long-grain": 781,
  "rice, brown, medium-grain, cooked": 824,
  "rice, brown, medium-grain": 803,
  "rice, white, glutinous, cooked": 735,
  "rice, white, glutinous": 781,
  "rice, white, long-grain, parboiled, cooked": 667,
  "rice, white, long-grain, parboiled, dry": 781,
  "rice, white, long-grain, regular, cooked": 667,
  "rice, white, long-grain, regular": 781,
  "rice, white, medium-grain, cooked": 786,
  "rice, white, medium-grain": 824,
  "rice, white, short-grain, cooked": 786,
  "rice, white, short-grain": 845,
  "rose hips, wild": 536,
  "rosemary, fresh": 114,
  rowal: 963,
  rye: 714,
  "salt, course, morton": 997,
  "salt, kosher, diamond crystal": 659,
  "salt, maldon": 570,
  "salt, table": 1234,
  "sauce, barbecue": 1208,
  "sauce, cheese": 1065,
  "sauce, cocktail": 1014,
  "sauce, fish": 1217,
  "sauce, hoisin": 1082,
  "sauce, oyster": 1217,
  "sauce, pasta, spaghetti/marinara": 1086,
  "sauce, peanut": 1217,
  "sauce, pepper or hot": 953,
  "sauce, pizza, canned": 1065,
  "sauce, plum": 1289,
  "sauce, salsa": 1098,
  "sauce, sofrito": 870,
  "sauce, teriyaki": 1217,
  "sauce, worcestershire": 1162,
  "sauerkraut, canned": 600,
  "seaweed, spirulina, dried": 473,
  "seeds, pumpkin and squash seed kernels, dried": 545,
  "seeds, pumpkin and squash seed kernels, roasted": 498,
  "seeds, sesame butter, paste": 1082,
  "seeds, sesame butter, tahini": 1014,
  "seeds, sesame seeds, whole, dried": 608,
  "seeds, sunflower seed kernels, dried": 591,
  "seeds, sunflower seed kernels, dry roasted": 541,
  "seeds, sunflower seed kernels, oil roasted": 570,
  semolina: 705,
  "semolina flour": 705,
  "shortening, vegetable oil": 866,
  "potato chips": 143,
  "tortilla chips": 109,
  "trail mix": 634,
  sorghum: 811,
  "sour cream": 972,
  "soy meal, defatted": 515,
  "soy sauce": 1077,
  "soybeans, cooked, boiled": 727,
  "soybeans, dry roasted": 393,
  soybeans: 786,
  "soybeans, roasted": 727,
  "soybeans, sprouted, cooked, steamed": 397,
  "soybeans, sprouted": 295,
  soymilk: 1027,
  "spearmint, dried": 108,
  "spearmint, fresh": 385,
  "spelt, cooked": 819,
  "spelt, uncooked": 735,
  "spices, allspice, ground": 405,
  "spices, caraway seed": 453,
  "spices, celery seed": 439,
  "spices, chervil, dried": 128,
  "spices, chili powder": 541,
  "spices, cinnamon, ground": 527,
  "spices, cloves, ground": 439,
  "spices, coriander leaf, dried": 121,
  "spices, coriander seed": 338,
  "spices, curry powder": 426,
  "spices, dill seed": 446,
  "spices, dill weed, dried": 209,
  "spices, fenugreek seed": 750,
  "spices, garlic powder": 655,
  "spices, ginger, ground": 351,
  "spices, mace, ground": 358,
  "spices, marjoram, dried": 114,
  "spices, mustard seed, ground": 426,
  "spices, nutmeg, ground": 473,
  "spices, onion powder": 466,
  "spices, paprika": 459,
  "spices, parsley, dried": 108,
  "spices, pepper, red or cayenne": 358,
  "spices, poppy seed": 595,
  "spices, poultry seasoning": 297,
  "spices, pumpkin pie spice": 378,
  "spices, rosemary, dried": 223,
  "spices, saffron": 142,
  "spices, sage, ground": 135,
  "spices, savory, ground": 297,
  "spices, turmeric, ground": 635,
  "spinach, canned, drained solids": 904,
  "spinach, canned, solids and liquids": 989,
  "spinach, cooked": 760,
  "spinach, frozen, chopped or leaf, cooked": 803,
  "spinach, frozen, chopped or leaf, unprepared": 659,
  spinach: 126,
  "sugar substitute, splenda": 105,
  "sugar, caster, superfine": 951,
  "sugar, confectioner's, powdered, icing": 528,
  "sugar, dark brown, packed": 1010,
  "sugar, demerara": 929,
  "sugar, light brown, packed": 917,
  "sugar, maple": 608,
  "sugar, turbinado": 853,
  "sugar, white, granulated": 929,
  "sweetener, syrup, agave": 929,
  "syrups, chocolate, fudge-type": 1284,
  "syrups, corn, dark": 1386,
  "syrups, corn, high-fructose": 1310,
  "syrups, corn, light": 1441,
  "syrups, grenadine": 1352,
  "syrups, malt": 1403,
  "syrups, maple": 1331,
  "syrups, sorghum": 1394,
  "tea, brewed": 1000,
  "teff, cooked": 1065,
  "teff, uncooked": 815,
  tempeh: 701,
  "thyme, fresh": 162,
  tofu: 1065,
  "tomato juice, canned": 1027,
  "tomato products, canned, paste": 1115,
  "tomato products, canned, puree": 1056,
  "tomato sauce, canned": 1035,
  "tomatoes, crushed, canned": 1022,
  "tomatoes, sun-dried": 228,
  "tomatoes, sun-dried, packed in oil": 464,
  "toppings, butterscotch or caramel": 1352,
  triticale: 811,
  "vanilla extract": 879,
  "vinegar, balsamic": 1077,
  "vinegar, cider": 1010,
  "vinegar, distilled, white": 1005,
  "vinegar, red wine": 1010,
  water: 1000,
  "wheat bran, crude": 245,
  "wheat germ, crude": 486,
  "wheat, durum": 811,
  "wheat, hard red or white": 811,
  "wheat, soft red winter": 710,
  "wheat, sprouted": 456,
  "whey, acid, dried": 240,
  "whey, acid, fluid": 1039,
  "whey, sweet, dried": 612,
  "whey, sweet, fluid": 1039,
  "wild rice, cooked": 693,
  "wild rice": 676,
  "yeast extract spread": 1217,
  "yeast, baker's, active dry": 730,
  "yeast, nutritional": 422,
  yogurt: 959,
  "zucchini, shredded": 659,
};

export default densities;
