import { Ingredient } from "index";
import { units } from "./units";
import { Tooltip } from "react-tooltip";
import { OxfordComma } from "./utils";
import clsx from "clsx";

export const titleCase = (str: string): string => {
  const words = str.split(" ");
  const titleCasedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return titleCasedWords.join(" ");
};

export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const toPlaintext = (str: string): string => {
  const boldAndItalicRegex = /(\*\*|__|\*|_)(.+?)\1/g;
  const timeRegex = /:time(?:\[(.*?)\])?\{min=(\d+)\}/g;
  const tempRegex = /:(?:temp|preheat)(?:\[(.*?)\])?\{([fd])=(\d+)\}/g;

  const strippedText = str
    .replace(boldAndItalicRegex, "$2")
    .replace(timeRegex, (match, timeText, minutes) =>
      timeText ? timeText : `${minutes}min`
    )
    .replace(tempRegex, (match, tempText, unit, temperature) =>
      tempText ? tempText : `${temperature}º${unit.toUpperCase()}`
    );

  return strippedText;
};

export const typesetFraction = (amount: number): string => {
  let whole = Math.floor(amount);
  let fraction = amount % 1;
  if (fraction < 0.1) return `${whole || ""}`.trim();
  if (fraction > 0.9) return `${whole + 1}`.trim();
  let fractions: [number, number, string][] = [
    [1, 8, "⅛"],
    [1, 5, "⅕"],
    [1, 4, "¼"],
    [1, 3, "⅓"],
    [3, 8, "⅜"],
    [2, 5, "⅖"],
    [1, 2, "½"],
    [3, 5, "⅗"],
    [5, 8, "⅝"],
    [2, 3, "⅔"],
    [3, 4, "¾"],
    [4, 5, "⅘"],
    [7, 8, "⅞"],
  ];

  for (let i = 1; i < fractions.length; i++) {
    let fltj = fractions[i][0] / fractions[i][1];
    if (fraction < fltj) {
      let flti = fractions[i - 1][0] / fractions[i - 1][1];
      let symbol =
        fraction < (flti + fltj) / 2 ? fractions[i - 1][2] : fractions[i][2];
      return `${whole || ""}${symbol}`.trim();
    }
  }
  return `${whole + 1}`;
};

export const typesetAmount = (
  amount: number | undefined,
  unit?: string,
  range?: number | undefined
): string => {
  if (!amount) return "";
  if (unit === "g" || unit === "ml") {
    let roundAmound = Math.round(amount);
    if (amount > 8 && amount < 100) {
      roundAmound = Math.round(amount / 5) * 5;
    } else if (amount >= 100) {
      roundAmound = Math.round(amount / 10) * 10;
    }
    return `${roundAmound}${unit}`;
  }

  let pluralizedUnit =
    unit && amount > 1.1 && units[unit] && units[unit].plural
      ? units[unit].plural
      : unit
      ? unit
      : "";

  if (amount === 0) return "0";
  if (range)
    return `${typesetFraction(amount)}–${typesetFraction(
      range
    )} ${pluralizedUnit}`.trim();
  return `${typesetFraction(amount)} ${pluralizedUnit}`.trim();
};

const typesetIngredientPlaintext = (ingredient: Ingredient): string => {
  const prettyAmount = typesetAmount(
    ingredient.range || ingredient.amount,
    ingredient.unit
  );
  if (!ingredient.substitutions)
    return `${prettyAmount} ${ingredient.name}`.trim();
  else
    return `${prettyAmount} ${
      ingredient.name
    } (or ${ingredient.substitutions.join(", ")})`.trim();
};

const typesetIngredientJSX = (ingredient: Ingredient) => {
  let prettyAmount = typesetAmount(
    ingredient.amount,
    ingredient.unit,
    ingredient.range
  );
  const amount =
    prettyAmount !== "" ? <b className="quantity">{prettyAmount}</b> : "";
  const noAmount = prettyAmount === "";
  if (!ingredient.substitutions)
    return (
      <span className={clsx("ingredient", { noAmount: noAmount })}>
        {amount} <span className="name">{ingredient.name}</span>
      </span>
    );
  else
    return (
      <span
        id={`ingredient-${ingredient.name}`}
        className={clsx("ingredient", "hasSubstitutions", {
          noAmount: noAmount,
        })}
      >
        <Tooltip
          anchorId={`ingredient-${ingredient.name}`}
          content={
            "Substitutions: " + OxfordComma(ingredient.substitutions, "or")
          }
          className="tooltip"
        />
        {amount} <span className="name">{ingredient.name}</span>
      </span>
    );
};

type typesetOptions = {
  plaintext: boolean;
};
export const typesetIngredient = (
  ingredient: Ingredient,
  options: typesetOptions | undefined = { plaintext: false }
): string | JSX.Element => {
  if (options.plaintext) return typesetIngredientPlaintext(ingredient);
  return typesetIngredientJSX(ingredient);
};
