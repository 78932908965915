import { Ingredient } from "index";
import numericQuantity from "numeric-quantity";
import { alternates } from "./units";

const ingredientRegex = new RegExp(
  "(?<name>[^,(]+)(?:,\\s*(?<preparation>[^(]+))?(?:\\(\\s*(?<substitutions>.+)\\))?",
  "u"
);

/* -------------------------------------------------------------------------- */
/*         The following code generates the regex used in production:         */
/* -------------------------------------------------------------------------- */

// const fractions = new RegExp("(?:[½⅓⅔¼¾⅕⅖⅗⅘⅙⅚⅛⅜⅝⅞]|\\d+/\\d+)", "u");
// const unitsRegex = Object.keys(alternates).join("|");

// const numericRegex = new RegExp(
//   `\\d+(?:\\s*${fractions.source}|\\.\\d+)?|${fractions.source}`,
//   "u"
// );
// const seperatorRegex = new RegExp("\\s*(?:-|–|—|or|to)\\s*", "u");

// const ingredientRegexSource = new RegExp(
//   `^(?<amount>${numericRegex.source})?(?:${seperatorRegex.source}(?<range>${numericRegex.source}))?\\s*(?:\\s*(?<unit>${unitsRegex}))?\\s+${ingredientRegex.source}$`,
//   "ui"
// );

// console.log(ingredientRegexSource.source);

/* -------------------------------------------------------------------------- */

const fullIngredientRegex =
  /^(?<amount>\d+(?:\s*(?:[½⅓⅔¼¾⅕⅖⅗⅘⅙⅚⅛⅜⅝⅞]|\d+\/\d+)|\.\d+)?|(?:[½⅓⅔¼¾⅕⅖⅗⅘⅙⅚⅛⅜⅝⅞]|\d+\/\d+))?(?:\s*(?:-|–|—|or|to)\s*(?<range>\d+(?:\s*(?:[½⅓⅔¼¾⅕⅖⅗⅘⅙⅚⅛⅜⅝⅞]|\d+\/\d+)|\.\d+)?|(?:[½⅓⅔¼¾⅕⅖⅗⅘⅙⅚⅛⅜⅝⅞]|\d+\/\d+)))?\s*(?:\s*(?<unit>milligram|milligrams|mg|gram|grams|g|kilogram|kilograms|kg|ounce|ounces|oz|pound|pounds|lbs|lb|milliliter|milliliters|ml|centiliter|centiliters|cl|deciliter|deciliters|dl|liter|liters|l|teaspoon|teaspoons|tsp|tablespoon|tablespoons|tbs|tbsp|fluid ounce|fluid ounces|floz|fl-oz|cups|cup|pints|pt|pts|pnt|pnts|quart|quarts|qts|qt|gallon|gallons|gals|gal|meter|meters|metre|metres|m|pinches|pinch|bunches|a bunch|bunch|large|cloves|clove|small|cans|can|sprigs|sprig))?\s+(?<name>[^,(]+)(?:,\s*(?<preparation>[^(]+))?(?:\(\s*(?<substitutions>.+)\))?$/iu;

type matchGroups = {
  amount: string;
  range?: string;
  unit?: string;
  name: string;
  preparation?: string;
  substitutions?: string;
};

export const parseIngredient = (raw: string) => {
  const regex = /[\d½⅓⅔¼¾⅕⅖⅗⅘⅙⅚⅛⅜⅝⅞].*/.test(raw)
    ? fullIngredientRegex
    : ingredientRegex;
  const ingredient: Ingredient = {
    name: "",
  };
  const match = regex.exec(raw);
  if (match) {
    const groups = match.groups as matchGroups;
    if (groups.amount) ingredient.amount = numericQuantity(groups.amount);
    if (groups.range) ingredient.range = numericQuantity(groups.range);
    if (groups.unit) ingredient.unit = alternates[groups.unit.toLowerCase()];
    if (groups.preparation)
      ingredient.preparation = groups.preparation.split(",");
    if (groups.substitutions)
      ingredient.substitutions = groups.substitutions
        .split(",")
        .map((s) => s.trim());
    if (groups.name) ingredient.name = groups.name.trim();
    else {
      console.error("Could not parse", raw, groups);
    }
  } else {
    ingredient.name = raw;
  }

  return ingredient;
};
