import React from "react";
import ReactMarkdown from "react-markdown";
import remarkDirective from "remark-directive";
import remarkDirectiveRehype from "remark-directive-rehype";
import { Temperature, Hint, Time, InlineIngredient } from "./inline";
import Prep from "./Prep";
import { Prep as PrepProps } from "index";

// @ts-ignore
const Sub = (attrs) => {
  return attrs.children;
};

const StepSection = ({ steps, prep }: { steps: string; prep: PrepProps }) => {
  return (
    <div className="Step">
      <Prep prep={prep} />
      <div className="Steps">
        <h2>Steps</h2>
        <ReactMarkdown
          remarkPlugins={[remarkDirective, remarkDirectiveRehype]}
          components={{
            p: "span",
            // @ts-ignore
            i: InlineIngredient,
            preheat: Temperature,
            temp: Temperature,
            time: ({ node, ...props }) => (
              // @ts-ignore
              <Time min={props.min} name="Set a timer">
                {props.children}
              </Time>
            ),
            hint: Hint,
            sub: Sub,
          }}
        >
          {steps}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default StepSection;
