import React from "react";
import Recipe from "./Recipe";
import Overview from "./Overview";
import "../css/App.css";
import "../css/Admin.css";
import {
  createBrowserRouter,
  RouterProvider,
  useParams,
} from "react-router-dom";
import { ErrorBoundary, PageNotFound } from "./Errors";
import TimerProvider from "../lib/timers";
import ShoppingListProvider from "../lib/shoppinglist";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import Admin from "./Admin";

const RecipePage = () => {
  let params = useParams();
  let recipeId = params.id as string;
  return (
    <ShoppingListProvider>
      <Recipe recipeID={recipeId} />
    </ShoppingListProvider>
  );
};

const PrintingFeatures = () => (
  <>
    <div className="bleed"></div>
    <div className="safeMargin"></div>
  </>
);

const router = createBrowserRouter([
  { path: "/", element: <Overview />, errorElement: <ErrorBoundary /> },
  {
    path: "/r/:id/edit",
    element: <Admin />,
    errorElement: <ErrorBoundary />,
  },
  { path: "/r/:id", element: <RecipePage />, errorElement: <ErrorBoundary /> },
  { path: "*", element: <PageNotFound /> },
]);

const Dainty = () => (
  <TimerProvider>
    <PrintingFeatures />
    <ToastContainer />
    <RouterProvider router={router} />
  </TimerProvider>
);

export default Dainty;
