import React, { useCallback, useEffect, useState } from "react";
import { PrepTime, RecipeCard as RecipeCardResult } from "../types";
import Ingredients from "./Ingredients";
import Steps from "./Steps";
import QRCode from "react-qr-code";

import ReactMarkdown from "react-markdown";
import Timers from "./Timer";
import { ShoppingListContext } from "../lib/shoppinglist";
import { supabase } from "../supabaseClient";
import RecipeMeta from "./RecipeMeta";
import { RecipeNotFound } from "./Errors";
import Loader from "./Loader";
import { findPreheatingInstructions, recipeImage } from "../lib/utils";

const formatTime = (minutes: number | undefined) => {
  if (!minutes) return "";
  if (minutes < 60) return `${minutes} min`;
  if (minutes > 1440) {
    const days = Math.floor(minutes / 1440);
    const quarter = Math.round((minutes % 1440) / 480);
    const fraction = ["", "¼", "½", "¾"][quarter];
    return `${days}${fraction} days`.trim();
  }
  const hours = Math.floor(minutes / 60);
  const quarter = Math.round((minutes % 60) / 15);
  const fraction = ["", "¼", "½", "¾"][quarter];
  return `${hours}${fraction} h`.trim();
};

const Duration = ({ active, total }: PrepTime) => {
  if (total)
    return (
      <div className="duration">
        {formatTime(active)} <span className="unit">active</span> /{" "}
        {formatTime(total)} <span className="unit">total</span>
      </div>
    );
  return (
    <div className="duration">
      {formatTime(active)} <span className="unit">active</span>
    </div>
  );
};
const Category = ({ category, left }: { category: string; left?: boolean }) => (
  <div className={"Category-Wrapper " + (left ? " Category-Left" : "")}>
    <div className={`Category Category-${category}`}>{category}</div>
  </div>
);

const Header = ({
  category,
  name,
  blank = false,
}: {
  category: string;
  name: string;
  blank: boolean;
}) => (
  <div className={`PrintHeader Category-${category}`}>
    <div className="PrintHeader-Name">{!blank && name}</div>
    <div className="PrintHeader-Category">{category}</div>
  </div>
);

type MobileSection = "ingredients" | "prep" | "steps";

const LoadingCard = () => {
  return (
    <div className="Recipe">
      <header className="Recipe-header"></header>
      <div className="Recipe-body">
        <h1>Loading...</h1>

        <div className="Recipe-content">
          <Loader loading={true} />
        </div>
      </div>
    </div>
  );
};

const RecipeCardWrapper = ({ recipeID }: { recipeID: string }) => {
  const [recipe, setRecipe] = useState<RecipeCardResult>();

  const loadRecipe = useCallback(async () => {
    const { data, error } = await supabase
      .from("recipes")
      .select(
        `
    name,
    slug,
    layout,
    description,
    category,
    img,
    yield_amount,
    yield_unit,
    active_time,
    total_time,
    steps,
    prep,
    ingredients (id, name, amount, unit, range, preparation, substitutions, is_header)
    `
      )
      .eq("slug", recipeID)
      .limit(1)
      .single();
    if (error) return <RecipeNotFound />;
    if (data) {
      (data as RecipeCardResult).ingredients.sort(
        (a, b) => (a.id as number) - (b.id as number)
      );
      setRecipe(data as RecipeCardResult);
    }
  }, [recipeID]);

  useEffect(() => {
    loadRecipe();
  }, [loadRecipe]);

  if (!recipe) return <LoadingCard />;
  return (
    <>
      <RecipeMeta {...recipe} />
      <RecipeCard {...recipe} />
    </>
  );
};

const RecipeCard = ({
  layout,
  name,
  slug,
  description,
  category,
  img,
  yield_amount,
  yield_unit,
  total_time,
  active_time,
  ingredients,
  steps,
  prep,
}: RecipeCardResult) => {
  const { setIngredients, setIncrement } =
    React.useContext(ShoppingListContext);

  const [mobileSection, setMobileSection] =
    useState<MobileSection>("ingredients");

  const menuButton = (label: MobileSection) => (
    <li
      className={mobileSection === label ? "active" : ""}
      onClick={() => setMobileSection(label)}
    >
      {label}
    </li>
  );

  useEffect(() => {
    setIngredients(ingredients);
    setIncrement(yield_amount);
  }, [ingredients, setIngredients, setIncrement, yield_amount]);

  useEffect(() => {
    document.title = `Manuʼs Recipes — ${name}`;
  }, [name]);

  const preheat = findPreheatingInstructions(steps);
  const parsedPrep = { ...(prep || {}), ...preheat };
  // Replace that if you want full-res images
  const imgUrl = recipeImage(slug, 900, 300);

  return (
    <>
      <Header name={name} category={category} blank={layout === "Blank"} />
      <Timers />

      <div className={`Recipe Layout-${layout || "Default"}`}>
        <header
          className="Recipe-header"
          style={{ backgroundImage: `url(${imgUrl})` }}
        ></header>
        <div className="Recipe-body">
          <h1>
            {name}
            <Duration active={active_time} total={total_time} />
            <Category category={category} />
          </h1>

          <ReactMarkdown
            children={description}
            components={{
              p: ({ node, ...props }) => (
                <p className="description" {...props} />
              ),
            }}
          />
          <div className={`Recipe-content Mobile-${mobileSection}`}>
            <menu>
              {menuButton("ingredients")}
              {menuButton("prep")}
              {menuButton("steps")}
            </menu>
            <Ingredients yield_amount={yield_amount} yield_unit={yield_unit} />
            <Steps steps={steps} prep={parsedPrep} />
          </div>
          <div className="QR">
            <QRCode
              value={`https://manu.recipes/r/${slug}`}
              size={64}
              bgColor="#f5f7f8"
              fgColor="#8c9ca9"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RecipeCardWrapper;
