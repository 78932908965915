export const slugify = (str: string): string =>
  // Replace all letters with accents with their unaccented versions
  str
    .toLowerCase()
    .replace(/[àáâä]/g, "a")
    .replace(/[èéêë]/g, "e")
    .replace(/[ìíîï]/g, "i")
    .replace(/[òóôö]/g, "o")
    .replace(/[ùúûü]/g, "u")
    .replace(/[ç]/g, "c")
    .trim();

export const OxfordComma = (list: string[], conj: string = "and") => {
  if (list.length === 1) {
    return list[0];
  }
  if (list.length === 2) {
    return `${list[0]} ${conj} ${list[1]}`;
  }
  return `${list.slice(0, -1).join(", ")}, ${conj} ${list[list.length - 1]}`;
};

const irregularVerbs: { [verb: string]: string } = {
  cut: "cut",
  beat: "beat",
  break: "broken",
  bring: "brought",
  heat: "hot",
  freeze: "frozen",
  grind: "ground",
  quarter: "quartered",
  mix: "mixed",
  spread: "spread",
  soften: "softened",
  tear: "torn",
  thaw: "thawed",
};

export const toSimplePast = (verb: string): string => {
  if (!verb) {
    return "";
  }
  const irregularPast = irregularVerbs[verb];
  if (irregularPast) {
    return irregularPast;
  }
  // If the verb ends with 'e', just add 'd'
  if (verb.endsWith("e")) {
    return verb + "d";
  }

  // If the verb ends with a consonant followed by 'y', change the 'y' to 'ied'
  const lastChar = verb.charAt(verb.length - 1);
  const secondToLastChar = verb.charAt(verb.length - 2);
  const thirdToLastChar = verb.charAt(verb.length - 3);
  const vowels = ["a", "e", "i", "o", "u"];

  if (lastChar === "y" && !vowels.includes(secondToLastChar.toLowerCase())) {
    return verb.slice(0, -1) + "ied";
  }

  // If the verb ends with a single consonant after a single vowel, double the final consonant and add 'ed'
  if (
    !vowels.includes(lastChar.toLowerCase()) &&
    vowels.includes(secondToLastChar.toLowerCase()) &&
    !vowels.includes(thirdToLastChar.toLowerCase())
  ) {
    return verb + lastChar + "ed";
  }

  // Add 'ed' for other cases
  return verb + "ed";
};

type PreheatResult = {
  preheat?: number;
  preheatUnit?: "c" | "f";
};

export const findPreheatingInstructions = (text: string): PreheatResult => {
  const preheatRegex = /:preheat(?:\[(.*?)\])?\{([cf])=(\d+)\}/;
  const match = text.match(preheatRegex);

  if (match) {
    const preheat = parseInt(match[3], 10);
    const preheatUnit = match[2] as "c" | "f";
    return { preheat, preheatUnit };
  }

  return {};
};

export const recipeImage = (slug: string, width: number, height: number) => {
  return `https://yfmnrcbenkzuksjvquxc.supabase.co/storage/v1/render/image/public/images/${slug}?width=${width}&height=${height}&resize=cover`;
};
