import React, { useEffect, useState } from "react";
import { useAutosave } from "react-autosave";
import { supabase } from "../../supabaseClient";
import Loader from "../Loader";
import clsx from "clsx";

type EditableProps = {
  field: string;
  className?: string;
  value: any;
  table: string;
  id: number;
  textarea?: boolean;
  json?: boolean;
};

const Editable = ({
  field,
  value,
  table,
  id,
  className,
  json = false,
  textarea = false,
}: EditableProps) => {
  const [currentValue, setCurrentValue] = useState(
    json && value ? JSON.stringify(value) : value
  );
  const [isValid, setIsValid] = useState(true);
  const [hasChanged, setHasChanged] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (json && currentValue !== "") {
      try {
        JSON.parse(currentValue as string);
        setIsValid(true);
      } catch (e) {
        setIsValid(false);
      }
    } else {
      setIsValid(true);
    }
  }, [currentValue, json]);

  const save = async () => {
    if (!isValid) return;
    setSaving(true);
    let value;
    if (json) {
      value = currentValue === "" ? null : JSON.parse(currentValue as string);
    } else {
      value = currentValue;
    }

    const { error } = await supabase
      .from(table)
      .update({
        [field]: value,
      })
      .eq("id", id);
    if (error) console.log(error);
    setSaving(false);
    setHasChanged(false);
  };

  useAutosave({ data: currentValue, onSave: save });

  const classes = clsx("Editable", className, {
    "has-changed": hasChanged,
    error: !isValid,
  });

  if (textarea) {
    return (
      <>
        <textarea
          rows={10}
          className={classes}
          onChange={(e) => {
            setHasChanged(true);
            setCurrentValue(e.target.value);
          }}
        >
          {currentValue || ""}
        </textarea>
        <Loader loading={saving} inline />
      </>
    );
  }

  return (
    <>
      <input
        type="text"
        className={classes}
        value={currentValue || ""}
        onChange={(e) => {
          setHasChanged(true);
          setCurrentValue(e.target.value);
        }}
      />
      <Loader loading={saving} inline />
    </>
  );
};

export default Editable;
