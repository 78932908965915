import { useMemo } from "react";
import { convert } from "../lib";
import { AdminIngredientResult } from "index";

type NutritionProps = {
  ingredients: AdminIngredientResult[];
};

const Nutrition = ({ ingredients }: NutritionProps) => {
  const nutrition = useMemo(() => {
    const totalNutrition = {
      weight: 0,
      water: 0,
      kcal: 0,
      protein: 0,
      carbs: 0,
      fiber: 0,
      sugar: 0,
      total_fat: 0,
      saturated_fat: 0,
    };

    ingredients.forEach((ingredient) => {
      const { amount, unit, base } = ingredient;
      if (!ingredient.is_header && base && amount && unit) {
        const { density, nutrition } = base;
        const converted = convert(ingredient, "metric");
        const weight =
          converted.unit === "g"
            ? (converted.amount as number)
            : ((converted.amount as number) * (density || 1000)) / 1000;
        totalNutrition.weight += weight;
        Object.keys(nutrition).forEach((key) => {
          totalNutrition[key as keyof typeof nutrition] +=
            (weight * nutrition[key as keyof typeof nutrition]) / 100;
        });
      } else {
        console.log(`skipping ${ingredient.name}`);
      }
    });

    const totalWeight = totalNutrition.weight;
    Object.keys(totalNutrition).forEach((key) => {
      totalNutrition[key as keyof typeof nutrition] *= 100 / totalWeight;
    });

    return totalNutrition;
  }, [ingredients]);

  return (
    <div className="Nutrition">
      <h2>Nutrition</h2>
      <ul>
        <li>
          <strong>Calories</strong> {Math.round(nutrition.kcal)}
        </li>
        <li>
          <strong>Total Fat</strong> {Math.round(nutrition.total_fat)}
          <ul>
            <li>Saturated Fat {Math.round(nutrition.saturated_fat)}</li>
          </ul>
        </li>
        <li>
          <strong>Total Carbohydrate</strong> {Math.round(nutrition.carbs)}
          <ul>
            <li>Dietary Fiber {Math.round(nutrition.fiber)}</li>
            <li>Sugar {Math.round(nutrition.sugar)}</li>
          </ul>
        </li>
        <li>
          <strong>Protein</strong> {Math.round(nutrition.protein)}
        </li>
      </ul>
    </div>
  );
};

export default Nutrition;
