import React, { useEffect, useState } from "react";
import { ShoppingListContext } from "../lib/shoppinglist";
import { capitalize, toPlaintext, typesetIngredient } from "../lib/typeset";
import { Ingredient, Prep as PrepType } from "../types";
import { Temperature } from "./inline";
import { OxfordComma, toSimplePast } from "../lib/utils";
import ReactMarkdown from "react-markdown";

const PrepStep = ({ ingredient }: { ingredient: Ingredient }) => {
  const [done, setDone] = useState(false);
  if (!ingredient.preparation) return null;

  return (
    <li
      className={`Prep-Item ${done ? "done" : ""}`}
      onClick={() => setDone((done) => !done)}
    >
      <span className="print">
        {capitalize(OxfordComma(ingredient.preparation))} {ingredient.name}
      </span>
      <span className="full">
        <b> {typesetIngredient(ingredient)}:</b>{" "}
        {OxfordComma(ingredient.preparation.map((step) => toSimplePast(step)))}
      </span>
    </li>
  );
};

const SimplePrepStep = ({ text }: { text: string }) => {
  const [done, setDone] = useState(false);

  return (
    <li
      className={`Prep-Item ${done ? "done" : ""}`}
      onClick={() => setDone((done) => !done)}
    >
      <span className="print">{toPlaintext(text)}</span>
      <span className="full">
        <ReactMarkdown>{text}</ReactMarkdown>
      </span>
    </li>
  );
};

type PreheatProps = {
  temperature: number;
  unit: "f" | "c";
};

const Preheat = ({ temperature, unit }: PreheatProps) => {
  const [done, setDone] = useState(false);
  const tempProps = Object.fromEntries([[unit, temperature]]);
  return (
    <li
      className={`Prep-Item ${done ? "done" : ""}`}
      onClick={() => setDone((done) => !done)}
    >
      Preheat oven to <Temperature {...tempProps} />
    </li>
  );
};

const Prep = ({ prep }: { prep: PrepType }) => {
  const { ingredients } = React.useContext(ShoppingListContext);
  const [prepIngredients, setPrepIngredients] = React.useState<Ingredient[]>(
    []
  );

  useEffect(() => {
    setPrepIngredients(
      ingredients.filter((ingredient) => (ingredient as Ingredient).preparation)
    );
  }, [ingredients, setPrepIngredients]);

  if (prepIngredients.length === 0 && !prep.preheat) {
    return null;
  }

  return (
    <div className="Prep">
      <h2>Prep</h2>
      <ul>
        {prep && prep.preheat && (
          <Preheat temperature={prep.preheat} unit={prep.preheatUnit || "f"} />
        )}
        {prep &&
          prep.steps &&
          prep.steps.map((step, i) => <SimplePrepStep text={step} key={i} />)}
        {prepIngredients.map((ingredient, i) => (
          <PrepStep ingredient={ingredient} key={ingredient.name} />
        ))}
      </ul>
    </div>
  );
};

export default Prep;
