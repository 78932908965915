import React, { useContext, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkDirective from "remark-directive";
import remarkDirectiveRehype from "remark-directive-rehype";
import { ReactComponent as PlayIcon } from "../icons/play.svg";
import { ReactComponent as PauseIcon } from "../icons/pause.svg";
import { ReactComponent as CloseIcon } from "../icons/close.svg";
import { Timer as TimerProps, TimerContext } from "../lib/timers";
import { Hidden, SimpleTemperature, SimpleTime } from "./inline";

const Timer = ({ name, duration, paused, end }: TimerProps) => {
  const { pauseTimer, startTimer, removeTimer } = useContext(TimerContext);
  const [timeLeft, setTimeLeft] = useState(duration);

  const isCompleted = () => timeLeft <= 0;

  useEffect(() => {
    if (paused) return;
    const interval = setInterval(() => {
      let timeLeft =
        end && !paused ? (end - new Date().getTime()) / 1000 : duration;
      if (timeLeft <= 0) {
        let alarm = new Audio("/alarm.wav");
        alarm.play();
        pauseTimer(name);
      }
      setTimeLeft(timeLeft);
    }, 1000);

    return () => clearInterval(interval);
  }, [end, paused, duration, name, pauseTimer]);

  const hours = isCompleted() ? 0 : Math.floor(timeLeft / 3600);
  const minutes = isCompleted()
    ? 0
    : Math.floor((timeLeft - hours * 3600) / 60);
  const seconds = isCompleted() ? 0 : Math.round(timeLeft % 60);

  const handleClick = () => {
    if (isCompleted()) removeTimer(name);
    else if (paused) startTimer(name);
    else pauseTimer(name);
  };

  return (
    <div
      className={`Timer ${!paused && "Timer-Running"} ${
        isCompleted() && "Timer-Completed"
      }`}
      onClick={handleClick}
    >
      <div
        className="Timer-Progress"
        style={{ width: (1 - timeLeft / duration) * 100 + "%" }}
      />
      <ReactMarkdown
        remarkPlugins={[remarkDirective, remarkDirectiveRehype]}
        components={{
          p: ({ node, ...props }) => <div className="Timer-Name" {...props} />,

          preheat: SimpleTemperature,
          temp: SimpleTemperature,
          // @ts-ignore
          time: SimpleTime,
          hint: Hidden,
        }}
      >
        {name}
      </ReactMarkdown>
      <div className="Timer-Inner">
        {paused ? (
          <PlayIcon fill="#3e8fb0" height={24} width={24} />
        ) : (
          <PauseIcon fill="#3e8fb0" height={24} width={24} />
        )}
        <div className="countdown">
          {hours > 0 && `${hours}:`}
          {String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
        </div>
        <CloseIcon
          fill="#3e8fb0"
          className="CloseTimer"
          height={24}
          width={24}
          onClick={() => removeTimer(name)}
        />
      </div>
    </div>
  );
};

const Timers = () => {
  const { timers } = useContext(TimerContext);

  return (
    <div className="Timers">
      {timers.map((timer, i) => (
        <Timer key={i} {...timer} />
      ))}
    </div>
  );
};

export default Timers;
