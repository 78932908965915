import React from "react";
import { useRouteError } from "react-router-dom";

export function RecipeNotFound() {
  return (
    <div className="Recipe">
      <header
        className="Recipe-header"
        style={{
          backgroundImage: "url(/img/404.jpg)",
        }}
      ></header>
      <div className="Recipe-body">
        <h1>404 — Recipe not found</h1>
        <p className="description">
          Sorry, something went wrong here, and I couldn't find the recipe
          you're looking for. Have a look at{" "}
          <a
            href="https://www.reddit.com/r/shittyfoodporn/top/?t=month"
            title="r/shittyfoodporn"
          >
            r/shittyfoodporn
          </a>{" "}
          instead.
        </p>
      </div>
    </div>
  );
}

export function PageNotFound() {
  return (
    <div className="Recipe">
      <header
        className="Recipe-header"
        style={{
          backgroundImage: "url(/img/404.jpg)",
        }}
      ></header>
      <div className="Recipe-body">
        <h1>404 — Page not found</h1>
        <p className="description">This is not a URL I recognize.</p>
      </div>
    </div>
  );
}

export function ErrorBoundary() {
  let error: any = useRouteError();
  console.log("My error::", Object(error));

  return (
    <div className="Recipe">
      <header
        className="Recipe-header"
        style={{
          backgroundImage: "url(/img/404.jpg)",
        }}
      ></header>
      <div className="Recipe-body">
        <h1>Yikes. {error.name}!</h1>
        <h3>{error.message}</h3>
        <code>{error.stack}</code>
      </div>
    </div>
  );
}
